@import "../mainStyle/var";
@import "../mainStyle/mixin.scss";

.loginWrapper {
  height: 100vh;
  background-image: linear-gradient(
    to right bottom,
    #051937,
    #0b2046,
    #142655,
    #1f2d65,
    #2c3374
  );
  .formCardWrapper{
      @include element_center_mixin;
      color: #ffffff;
      height: 100vh;
      .error-message{
        color: $primary-color-red;
      }
  }
}
