.quotesCarouselWrapper{
    background-image: url("../../assets/images/team-bg.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    background-attachment: fixed;
    color:#f2f2f2;
    padding: 2rem;
    text-align: center;
    overflow: visible;
    .quotesCarouselText{
        font-size: 30px;
        font-weight: 700;
    }
}