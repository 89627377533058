@mixin bgImage_layer_mixin($image, $minHeight) {
  min-height: $minHeight;
  background-image: $image;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-attachment: fixed;
}

@mixin pagesBanner($rgba1, $rgba2, $image){
  height: 250px;
  position: relative;
  background-image: linear-gradient($rgba1, $rgba2), url($image);
  background-size: contain;
  background-repeat: no-repeat;
  background-attachment: fixed;
}

@mixin element_center_mixin{
    display: flex;
    justify-content: center;
    align-items: center;
}

@mixin circle_texture_mixin {
  width: 60px;
  height: 60px;
  top: -15px;
  left: -15px;
  position: absolute;
  z-index: -1;
  border-radius: 50%;
  background-color: $light-blueShade-color;
  box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;
}

@mixin title_mixin {
  font-family: $font-face;
  color: $primary-color-blue;
}
@mixin text_mixin {
  color: $gray-color;
  font-size: 16px;
}

@mixin floating_icon_mixin($bgColor, $color, $size, $width, $height){
  width: $width;
  height: $height;
  color: $color;
  font-size: $size;
  background-color: $bgColor;
}

@mixin clipShadow {
  box-shadow: rgba(0, 0, 0, 0.2) 0px 20px 30px;
}

@mixin rotation360deg($animationName, $animationDelay){
  animation: $animationName $animationDelay linear infinite;
}