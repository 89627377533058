@import "../../mainStyle/var";
@import "../../mainStyle/mixin.scss";
// linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)),

.counterWrapper {
  // @include bgImage_layer_mixin(url("../../assets/images/mednew.jpg"), 0);
  padding: 3% 0;
  text-align: center;
  .icon{
    font-size: 50px;
    color: $primary-color-blue;
    width: 100px;
    height: 100px;
    background-color: $light-shade;
    @include element_center_mixin;
    border-radius: 50%;
    margin: 0 auto;
  }
  .title{
    font-family:$font-face;
    color: $primary-color-blue;
  }
}

.counterDigit {
  color: #ffffff;
  font-family: $font-face;
  background-image: linear-gradient(
    to right,
    #d83940,
    #c0235e,
    #992773,
    #67307b,
    #2c3374
  );
  // font-size: 75px;
}
