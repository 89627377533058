@import "../../mainStyle/var";
@import "../../mainStyle/mixin.scss";

.stripWrapper {
  @include bgImage_layer_mixin(url("../../assets/images/stripeLayer.jpg"), 0);
  .stripeSubTitle {
    @include text_mixin;
    color: $primary-color-blue;
  }
  .stripeTitle {
    @include title_mixin;
    color: #666;
  }
}
