@import "../../mainStyle/var";
@import "../../mainStyle/mixin.scss";

.serviceGridWrapper {
    margin-bottom: 2rem;
  .iconCardWrapper {
    background-image: linear-gradient(
        rgba(255, 255, 255, 0.4),
        rgba(255, 255, 255, 0.4)
      ),
      url("../../assets/images/pattern-3.png");
    background-size: contain;
    background-repeat: no-repeat;
    cursor: pointer;
    padding: 1rem;
    transition: 0.5s ease;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px,
      rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
    transition: all 0.3s linear;
    margin: 0 0.5rem;
    text-align: center;
    height: 300px;
    .cardTitle {
      font-weight: 700;
      color: $primary-color-blue;
    }
    .homeServiceIcon {
      transition: all 0.3s linear;
      margin: 0 auto;
    }
    &:hover {
      border-bottom-right-radius: 30%;
      transform: scale(0.9);
    }
    &:hover .homeServiceIcon {
      transform: scale(0.8);
    }
  }
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
    .serviceGridWrapper{
        .iconCardWrapper{
            height: 360px;
        }
    }
  }
  
  /* Small devices (portrait tablets and large phones, 600px and up) */
  @media only screen and (min-width: 600px) {

  }
  
  /* Medium devices (landscape tablets, 768px and up) */
  @media only screen and (min-width: 768px) {

  }
  
  /* Large devices (laptops/desktops, 992px and up) */
  // @media only screen and (min-width: 992px) {}
  
  /* Extra large devices (large laptops and desktops, 1200px and up) */
  // @media only screen and (min-width: 1200px) {...}
