@import "../../mainStyle/var";
@import "../../mainStyle/mixin.scss";

.drawerWrapper {
  width: 500px;
  background-color: rgba(171, 194, 232, 0.1);
  height: 100%;
  @include element_center_mixin;
  text-align: center;
  .formSologan{
      font-size: 20px;
      font-weight: 700;
      color: $primary-color-red;
      margin: 10px;
  }
}
