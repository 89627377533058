@import "../../mainStyle/_var";
@import "../../mainStyle/mixin.scss";
@import url("https://fonts.googleapis.com/css2?family=Anton&display=swap");

.homeBanner {
  position: relative;
  @include bgImage_layer_mixin(url("../../assets/images/banner1.jpg"), 70vh);
  .innerWrapper {
    // @include element_center_mixin;
    width: 100%;
    height: 70vh;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    .bannerTitle {
      font-family: "Anton", sans-serif;
      color: #2A2F4F;
      letter-spacing: 1px;
      text-transform: uppercase;
      font-size: 70px;
    }
    .bannerSubTitle {
      font-size: 16px;
      color: #2B3A55;
      // font-family: $font-face;
    }
  }
  .bannerStickerAnim {
    position: absolute;
    top: 20%;
    left: 10%;
    @include rotation360deg(stickerAnim1, 30s);
  }
  @keyframes stickerAnim1 {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  .bannerStickerAnim2 {
    position: absolute;
    bottom: 20%;
    left: 45%;
    @include rotation360deg(stickerAnim2, 30s);
  }
  @keyframes stickerAnim2 {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
}

// banner Card Style ===== >>>
.bannerContainerWrapper {
  position: relative;
  margin-top: -5rem;
  .bannerCardWrapper {
    background-image: linear-gradient(
        rgba(255, 255, 255, 0.3),
        rgba(255, 255, 255, 0.3)
      ),
      url("../../assets/images/pattern-1.png");
    background-size: cover;
    background-repeat: no-repeat;
    border: 1px solid $light-blueShade-color;
    box-shadow: rgba(0, 0, 0, 0.1) -4px 9px 25px -6px;
    transition: all 0.3s linear;
    .bannerIcon {
      transition: all 0.3s linear;
    }
    .bannerCardTitle {
      @include title_mixin;
    }
    &:hover {
      border-bottom-right-radius: 30%;
      transform: scale(0.9);
    }
    &:hover .bannerIcon {
      transform: scale(0.8);
    }
  }
}

// Clip Comp Style ===== >>>
.clipContainerWrapper {
  padding: 5rem 0;
  position: relative;
  .beforeTextureImg {
    position: absolute;
    left: 0;
    bottom: 0;
    z-index: -1;
  }
  beforeTextureImg,
  beforeTextureImg2 img {
    width: 100%;
  }
  .beforeTextureImg2 {
    position: absolute;
    right: 0;
    bottom: 0;
    z-index: -1;
  }
  .clipGridWrapper {
    .clipContentWrapper {
      @include element_center_mixin;
      // padding: 0 3rem;
      padding-right: 2rem;
      text-align: justify;
      .clipBox {
        position: relative;
        .circleTexture {
          @include circle_texture_mixin;
        }
        .clipTitle {
          @include title_mixin;
        }
        .clipText {
          @include text_mixin;
          display: block;
          margin: 1rem;
        }
      }
    }
    .clipVideoWrapper {
      display: flex;
      justify-content: center;
      align-items: center;
      .clipVideoCard {
        @include clipShadow;
      }
    }
    .textureDesign {
      width: 130px;
      height: 130px;
      border-radius: 50%;
      background-image: linear-gradient(
        to right,
        #d83940,
        #c0235e,
        #992773,
        #67307b,
        #2c3374
      );
      opacity: 0.8;
      position: absolute;
      right: 43%;
      top: 15%;
      z-index: -1;
    }
    .clipSticker {
      position: absolute;
      left: 40%;
      bottom: 0;
      img {
        width: 150px;
      }
    }
  }
}

// Services Comp Style ===== >>>
.servicesGridWrapper {
  background-color: #f9f9f9;
  padding: 5rem 0;
  position: relative;
  .servicesContentWrapper {
    @include element_center_mixin;
    // padding: 0 3rem;
    padding-left: 2rem;
    text-align: justify;
    .servicesBox {
      position: relative;
      .circleTexture {
        @include circle_texture_mixin;
      }
      .servicesTitle {
        @include title_mixin;
      }
      .servicesText {
        @include text_mixin;
      }
    }
  }
  .servicesClipWrapper {
    .servicesClipBox {
      @include clipShadow;
    }
  }
  // .serviceIconWrapper {
  //   text-align: center;
  //   margin-bottom: 2rem;
  //   .serviceGridWrapper {
  //     .iconCardWrapper {
  //       background-image: linear-gradient(
  //           rgba(255, 255, 255, 0.4),
  //           rgba(255, 255, 255, 0.4)
  //         ),
  //         url("../../assets/images/pattern-3.png");
  //       background-size: contain;
  //       background-repeat: no-repeat;
  //       cursor: pointer;
  //       padding: 2rem;
  //       transition: 0.5s ease;
  //       box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px,
  //         rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
  //       transition: all 0.3s linear;
  //       .cardTitle {
  //         font-weight: 700;
  //         color: $primary-color-blue;
  //       }
  //       .homeServiceIcon {
  //         transition: all 0.3s linear;
  //       }
  //       &:hover {
  //         border-bottom-right-radius: 30%;
  //         transform: scale(0.9);
  //       }
  //       &:hover .homeServiceIcon {
  //         transform: scale(0.8);
  //       }
  //     }
  //   }
  // }
  .serviceSticker {
    position: absolute;
    right: 10%;
    bottom: 0;
    img {
      width: 150px;
    }
  }
}

// Specialities Comp Style ===== >>>
.specialitiesContainerWrapper {
  position: relative;
  padding: 5rem 0;
  .beforeTextureImgSpec {
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: -1;
  }
  .beforeTextureImgSpec img {
    width: 100%;
  }
  .specialitiesGridWrapper {
    .specialitiesContentWrapper {
      @include element_center_mixin;
      // padding: 0 3rem;
      padding-left: 2rem;
      .specialitiesBox {
        position: relative;
        .circleTexture {
          @include circle_texture_mixin;
        }
        .specialitiesTitle {
          @include title_mixin;
        }
        .specialitiesText {
          @include text_mixin;
          text-align: justify;
        }
        .specialitiesIconWrapper {
          margin: 1rem 0;
          text-align: center;
          .specIconBox {
            margin-right: 1rem;
            background-image: linear-gradient(to right, #d83940, #c0235e);
            width: 80px;
            height: 80px;
            border-radius: 10px;
            box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
            @include element_center_mixin;
            cursor: pointer;
            transition: all 0.5s linear;
            &:hover {
              transform: scale(0.9);
            }
          }
          .title{
            font-family: $font-face;
            color:#2c3374;
          }
        }
      }
    }
    .specialitiesClipWrapper {
      text-align: right;
      .specialitiesImage {
        img {
          @include clipShadow;
          width: 100%;
        }
      }
    }
  }
  .textureCircleDesign {
    width: 150px;
    height: 150px;
    border-radius: 50%;
    background-color: $light-blueShade-color;
    position: absolute;
    bottom: -10%;
    left: 45%;
    z-index: -1;
    // box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
  }
  .specialitiesSticker {
    position: absolute;
    right: 10%;
    bottom: 0;
    animation: mymove 5s ease infinite;
    animation-direction: alternate-reverse;
    img {
      width: 150px;
    }
  }
  @keyframes mymove {
    from {
      right: 10%;
    }
    to {
      right: 15%;
    }
  }
}

// feedback === >>>
.feedbackGridWrapper {
  padding: 3rem 0;
  position: relative;
  .beforeTextureImg {
    position: absolute;
    left: 0;
    bottom: 0;
    z-index: -1;
    img{
      width: 100%;
    }
  }
  .beforeTextureImg2 {
    position: absolute;
    right: 0;
    bottom: 0;
    z-index: -1;
    img{
      width: 100%;
    }
  }
  .iconCardWrapper {
  background-image: linear-gradient(
      rgba(255, 255, 255, 0.4),
      rgba(255, 255, 255, 0.4)
    ),
    url("../../assets/images/pattern-3.png");
  background-size: contain;
  background-repeat: no-repeat;
  cursor: pointer;
  padding: 1rem;
  transition: 0.5s ease;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px,
    rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
  transition: all 0.3s linear;
  margin: 0 0.5rem;
  text-align: center;
  height: 300px;
  .cardTitle {
    font-weight: 700;
    color: $primary-color-blue;
  }
  .homeServiceIcon {
    transition: all 0.3s linear;
    margin: 0 auto;
  }
  &:hover {
    border-bottom-right-radius: 30%;
    transform: scale(0.9);
  }
  &:hover .homeServiceIcon {
    transform: scale(0.8);
  }
}
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
  .homeBanner {
    .innerWrapper {
      height: 90vh;
      .bannerSubTitle {
        font-size: 14px;
      }
    }
  }

  // Clip Comp Style ===== >>>
  .clipContainerWrapper {
    .beforeTextureImg2 {
      right: 0;
      top: 0;
    }
    .clipGridWrapper {
      .clipContentWrapper {
        @include element_center_mixin;
        padding-right: 0;
        text-align: justify;
        .clipBox {
          .circleTexture {
            left: -5px;
          }
          .clipTitle {
            text-align: center;
          }
          .buttonStyle {
            text-align: center;
          }
        }
      }
      .clipVideoWrapper {
        padding-top: 2rem;
      }
      .textureDesign {
        right: 0;
        top: -5%;
      }
      .clipSticker {
        display: none;
      }
    }
  }

  // Services Comp Style ===== >>>
  .servicesGridWrapper {
    .servicesContentWrapper {
      margin-top: 2rem;
      padding: 0 1rem;
      .servicesBox {
        .circleTexture {
          left: 20%;
        }
        .servicesTitle {
          text-align: center;
        }
        .servicesText {
          text-align: justify;
        }
        .buttonStyle {
          text-align: center;
          margin-top: 1rem;
        }
      }
    }
    .serviceSticker {
      display: none;
    }
  }

  // Specialities Comp Style ===== >>>
  .specialitiesContainerWrapper {
    .specialitiesGridWrapper {
      .specialitiesContentWrapper {
        padding-left: 0;
        margin-top: 2rem;
        .specialitiesBox {
          position: relative;
          .circleTexture {
            left: 25%;
          }
          .specialitiesTitle {
            text-align: center;
          }
          .specialitiesText {
            // padding: 0 2rem;
            text-align: justify;
          }
          .buttonStyle {
            text-align: center;
            margin-top: 1rem;
          }
        }
      }
    }
    .textureCircleDesign {
      bottom: 10%;
      right: 0;
    }
    .specialitiesSticker {
      display: none;
    }
  }
}

// Info
.leftImageWrapper {
  @include element_center_mixin;
  justify-content: end;
}
.rightContentWrapper {
  @include element_center_mixin;
  justify-content: start;
  height: 100%;
  .title {
    font-family: $font-face;
    color: #2c3374;
  }
}

// how it work section
.howItWorkSection {
  position: relative;
  .beforeTextureImg2 {
    position: absolute;
    left: 0;
    bottom: 0;
    z-index: -1;
    img{
      width: 100%;
    }
  }
  .leftImageWrapper {
    @include element_center_mixin;
    justify-content: start;
  }
  .rightContentWrapper {
    @include element_center_mixin;
    justify-content: end;
    height: 100%;
    .title {
      font-family: $font-face;
      color: #2c3374;
    }
  }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
}

/* Large devices (laptops/desktops, 992px and up) */
// @media only screen and (min-width: 992px) {}

/* Extra large devices (large laptops and desktops, 1200px and up) */
// @media only screen and (min-width: 1200px) {...}

.videoClipWrapper {
  position: relative;
  cursor: pointer;
  .videoClipBtn {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}
.play-btn {
  width: 100px;
  height: 100px;
  background: radial-gradient(
    rgba(216, 57, 64, 0.8) 60%,
    rgba(255, 255, 255, 1) 62%
  );
  border-radius: 50%;
  position: relative;
  display: block;
  // margin: 100px auto;
  box-shadow: 0px 0px 25px 3px rgba(216, 57, 64, 0.8);
}

/* triangle */
.play-btn::after {
  content: "";
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translateX(-40%) translateY(-50%);
  transform: translateX(-40%) translateY(-50%);
  transform-origin: center center;
  width: 0;
  height: 0;
  border-top: 15px solid transparent;
  border-bottom: 15px solid transparent;
  border-left: 25px solid #fff;
  z-index: 100;
  -webkit-transition: all 400ms cubic-bezier(0.55, 0.055, 0.675, 0.19);
  transition: all 400ms cubic-bezier(0.55, 0.055, 0.675, 0.19);
}

/* pulse wave */
.play-btn:before {
  content: "";
  position: absolute;
  width: 150%;
  height: 150%;
  -webkit-animation-delay: 0s;
  animation-delay: 0s;
  -webkit-animation: pulsate1 2s;
  animation: pulsate1 2s;
  -webkit-animation-direction: forwards;
  animation-direction: forwards;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  -webkit-animation-timing-function: steps;
  animation-timing-function: steps;
  opacity: 1;
  border-radius: 50%;
  border: 5px solid rgba(255, 255, 255, 0.75);
  top: -30%;
  left: -30%;
  background: rgba(198, 16, 0, 0);
}

@-webkit-keyframes pulsate1 {
  0% {
    -webkit-transform: scale(0.6);
    transform: scale(0.6);
    opacity: 1;
    box-shadow: inset 0px 0px 25px 3px rgba(255, 255, 255, 0.75),
      0px 0px 25px 10px rgba(255, 255, 255, 0.75);
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 0;
    box-shadow: none;
  }
}

@keyframes pulsate1 {
  0% {
    -webkit-transform: scale(0.6);
    transform: scale(0.6);
    opacity: 1;
    box-shadow: inset 0px 0px 25px 3px rgba(255, 255, 255, 0.75),
      0px 0px 25px 10px rgba(255, 255, 255, 0.75);
  }
  100% {
    -webkit-transform: scale(1, 1);
    transform: scale(1);
    opacity: 0;
    box-shadow: none;
  }
}
