@import "../../mainStyle/var";
@import "../../mainStyle/mixin.scss";

.footerStripe {
  @include bgImage_layer_mixin(url("../../assets/images/team-bg.jpg"), 0);
  padding: 2rem;
  color: #f2f2f2;
  font-size: 16px;
  text-align: center;
  font-family: $font-face;
}

.footWrapper {
  padding: 1.5rem 0;
  // background: linear-gradient(to right, #ffffff, #f2f2f2);
  padding: 3rem;
  background-image: linear-gradient(
      rgba(236, 242, 255, 0.3),
      rgba(236, 242, 255, 0.4)
    ),
    url("../../assets/images/pattern-18.png");
  background-size: cover;
  background-repeat: no-repeat;
  .companyInfoBlock {
    padding: 2rem;
    .logo {
      width: 100px;
    }
    .companyInfo {
      @include text_mixin;
      font-weight: 500;
    }
  }
  .footerLinksWrapper {
    padding: 2rem 0;
    .footerLink {
      @include text_mixin;
    }
    &:hover {
      color: $primary-color-red;
    }
  }
  .footerLinkHead {
    @include title_mixin;
  }
  .footerIconStyle {
    font-size: 30px;
    color: #666;
    &:hover {
      color: $primary-color-red;
    }
  }
}

.footerBottom{
  text-align: center;
  padding: 1rem 0;
  color: #666;
}

.backToTopWrapper {
  width: 50px;
  height: 50px;
  background-color: rgba(216,57,64,0.8);
  color: $white-color;
  display: flex;
  cursor: pointer;
  justify-content: center;
  align-items: center;
  font-size: 20px;
  position: fixed;
  bottom: 1%;
  right: 0;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px,
    rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
  .footWrapper {
    padding: 0;
    .companyInfoBlock {
      .logo {
        width: 70px;
      }
    }
  }
  .backToTopWrapper {
    width: 40px;
    height: 40px;
    font-size: 18px;
  }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
// @media only screen and (min-width: 600px) {}

/* Medium devices (landscape tablets, 768px and up) */
// @media only screen and (min-width: 768px) {}

/* Large devices (laptops/desktops, 992px and up) */
// @media only screen and (min-width: 992px) {}

/* Extra large devices (large laptops and desktops, 1200px and up) */
// @media only screen and (min-width: 1200px) {...}
