// $main-primary-blue: #242F9B;
// $main-primary-red: #ED1B24;
$primary-color-blue: #2c3374;
$primary-color-red: #d83940;
$light-blueShade-color: #ECF2FF;
$white-color: #ffffff;
$gray-color: #666;
$icon-color: #ABC2E8;
$light-shade: rgba(243,251,254,0.7);

$red-color: red; //dummy

$font-face: 'Bebas Neue', cursive;