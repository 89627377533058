@import "../../mainStyle/var";
@import "../../mainStyle/mixin.scss";

.floatingIconWrapper {
  position: fixed;
  top: 40%;
  right: 0%;
  .watsappIcon {
    @include element_center_mixin;
    @include floating_icon_mixin(
      rgba(37, 211, 102, 0.6),
      $white-color,
      35px,
      60px,
      60px
    );
  }
  .phoneIcon {
    @include element_center_mixin;
    @include floating_icon_mixin(
      rgba(29, 161, 242, 0.6),
      $white-color,
      30px,
      60px,
      60px
    );
  }
  .emailIcon {
    @include element_center_mixin;
    @include floating_icon_mixin(
      rgba(10, 98, 142, 0.6),
      $white-color,
      30px,
      60px,
      60px
    );
  }
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
  .floatingIconWrapper {
    .watsappIcon {
      @include floating_icon_mixin(
        rgba(37, 211, 102, 0.6),
        $white-color,
        30px,
        48px,
        48px
      );
    }
    .phoneIcon {
      @include element_center_mixin;
      @include floating_icon_mixin(
        rgba(29, 161, 242, 0.6),
        $white-color,
        30px,
        48px,
        48px
      );
    }
    .emailIcon {
      @include element_center_mixin;
      @include floating_icon_mixin(
        rgba(10, 98, 142, 0.6),
        $white-color,
        30px,
        48px,
        48px
      );
    }
  }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
    .floatingIconWrapper {
        .watsappIcon {
          @include floating_icon_mixin(
            rgba(37, 211, 102, 0.6),
            $white-color,
            30px,
            50px,
            50px
          );
        }
        .phoneIcon {
          @include element_center_mixin;
          @include floating_icon_mixin(
            rgba(29, 161, 242, 0.6),
            $white-color,
            30px,
            50px,
            50px
          );
        }
        .emailIcon {
          @include element_center_mixin;
          @include floating_icon_mixin(
            rgba(10, 98, 142, 0.6),
            $white-color,
            30px,
            50px,
            50px
          );
        }
      }
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
}

/* Large devices (laptops/desktops, 992px and up) */
// @media only screen and (min-width: 992px) {}

/* Extra large devices (large laptops and desktops, 1200px and up) */
// @media only screen and (min-width: 1200px) {...}
