@import "../../mainStyle/var";
@import "../../mainStyle/mixin.scss";
@import url("https://fonts.googleapis.com/css2?family=Anton&display=swap");

.topHeaderWrapper {
  // @include bgImage_layer_mixin(url("../../assets/images/team-bg.jpg"), 0);
  background-image: linear-gradient(to left, #d83940, #c0235e, #992773, #67307b, #2c3374);
  @include element_center_mixin;
  justify-content: space-between;
  padding: 5px 2rem;
  color: $light-blueShade-color;
  .topStripIcon {
    color: $primary-color-red;
  }
  .topIcon{
    color: $white-color;
    padding: 0 3px;
    cursor: pointer;
    &:hover{
      color: $primary-color-red;
    }
  }
}

.logoWrapper {
  @include element_center_mixin;
  .logo {
    width: 60px;
    margin: 3px 0;
  }
  .logoTextWrapper {
    .logoText {
      font-size: 20px;
      letter-spacing: 1px;
      font-family: "Anton", sans-serif;
      color: $primary-color-blue;
    }
  }
}

.headerLinkList {
  width: 100%;
  @include element_center_mixin;
  color: $primary-color-blue;
  .headerLinks{
    background-color: $light-blueShade-color;
  }
}

.drawer-wrapper{
  width: 250px;
  height: 100vh;
  background-image: linear-gradient(rgba(236,242,255,0.1), rgba(236,242,255,0.2)), url("../../assets/images/pattern-18.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: left;
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
  .topHeaderWrapper {
    padding: 5px;
    .mailBox{
      font-size: 14px;
    }
  }
  .logoWrapper {
    .logo {
      width: 55px;
    }
  }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
// @media only screen and (min-width: 600px) {}

/* Medium devices (landscape tablets, 768px and up) */
// @media only screen and (min-width: 768px) {}

/* Large devices (laptops/desktops, 992px and up) */
// @media only screen and (min-width: 992px) {}

/* Extra large devices (large laptops and desktops, 1200px and up) */
// @media only screen and (min-width: 1200px) {...}
