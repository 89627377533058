.button {
  position: relative;
  overflow: hidden;
  height: 3rem;
  padding: 0 2rem;
  border-radius: 10px;
  // background: #d83940;
  background-image: linear-gradient(to right, #d83940, #c0235e, #992773, #67307b, #2c3374);
  background-size: 400%;
  color: #fff;
  border: none;
}

.button:hover::before {
  transform: scaleX(1);
}

.button-content {
  position: relative;
  z-index: 1;
}

.button::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  transform: scaleX(0);
  transform-origin: 0 50%;
  width: 100%;
  height: inherit;
  border-radius: inherit;
  // background: linear-gradient(
  //   82.3deg,
  //   rgba(150, 93, 233, 1) 10.8%,
  //   rgba(99, 88, 238, 1) 94.3%
  // );
  background-image: linear-gradient(to right, #d83940, #c0235e, #992773, #67307b, #2c3374);
  transition: all 0.475s;
}